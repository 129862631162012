import React, { useCallback, useEffect, useMemo } from 'react';
import { UseFormSetValue } from 'react-hook-form';
import filter from 'lodash/filter';
import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';
import map from 'lodash/map';
import some from 'lodash/some';
import keys from 'lodash/keys';

import { HexColor } from '../../../../../../../../../types';
import {
  MessageColors,
  MessageFields,
  MessageFileAttachmentIDs,
  MessageSelectedLifestyleIDs,
  MessageSelectedMaterialIDs
} from '../../../../../../../messagesTypes';
import { ItemCreateMessageFormData } from '../../../../hooks/useItemCreateMessageForm';
import { ItemCreateMessageAttachmentsListImageTypes } from '../ItemCreateMessageAttachmentsListImage/ItemCreateMessageAttachmentsListImage.types';
import { SelectedProductID } from '../../../../../../../../selectedProducts/selectedProductsTypes';
import { SelectedLifestyleID } from '../../../../../../../../selectedLifestyles/selectedLifestylesTypes';
import { SelectedMaterialID } from '../../../../../../../../selectedMaterials/selectedMaterialsTypes';
import { FileAttachmentID } from '../../../../../../../../fileAttachments/fileAttachmentsTypes';

import {
  FETCH_DROPZONE_FILE_ATTACHMENTS_QUERY,
  FetchDropzoneFileAttachmentsQueryResponse
} from '../../../../../../../../fileAttachments/queries/fetchDropzoneFileAttachments.query';
import {
  FETCH_SELECTED_LIFESTYLES_QUERY,
  FetchSelectedLifestylesQueryResponse
} from '../../../../../../../../selectedLifestyles/queries/fetchSelectedLifestyles.query';
import {
  FETCH_SELECTED_PRODUCTS_QUERY,
  FetchSelectedProductsQueryResponse
} from '../../../../../../../../selectedProducts/queries/fetchSelectedProducts.query';
import {
  FETCH_SELECTED_MATERIALS_QUERY,
  FetchSelectedMaterialsQueryResponse
} from '../../../../../../../../selectedMaterials/queries/fetchSelectedMaterials.query';

import { ItemCreateMessageAttachmentsListColor } from '../ItemCreateMessageAttachmentsListColor';
import { DropzoneUploadedFileItem } from '../../../../../../../../../helpers/dropzone/DropzoneUploadedFile';

import { useFileAttachments } from '../../../../../../../../fileAttachments/hooks/useFileAttachments';

import { useSelectedProducts } from '../../../../../../../../selectedProducts/hooks/useSelectedProducts';
import { useSelectedLifestyles } from '../../../../../../../../selectedLifestyles/hooks/useSelectedLifestyles';
import { useSelectedMaterials } from '../../../../../../../../selectedMaterials/hooks/useSelectedMaterials';

import { ItemCreateMessageAttachmentsListFile } from '../ItemCreateMessageAttachmentsListFile';
import { ItemCreateMessageAttachmentsListImage } from '../ItemCreateMessageAttachmentsListImage';
import { ItemCreateMessageAttachmentsListUploadingFile } from '../ItemCreateMessageAttachmentsListUploadingFile';

import { AlertMessage } from '../../../../../../../../../helpers/AlertMessage';

import { Files } from '../../../../../../../../../utils/Files';
import { sortMessageFileAttachments } from '../../../../../../../utils/sortMessageFileAttachments';

import { SelectedLifestyleCache } from '../../../../../../../../selectedLifestyles/SelectedLifestyleCache';
import { SelectedProductCache } from '../../../../../../../../selectedProducts/SelectedProductCache';
import { SelectedMaterialCache } from '../../../../../../../../selectedMaterials/SelectedMaterialCache';

import { FileAttachmentCache } from '../../../../../../../../fileAttachments/FileAttachmentCache';

interface ItemCreateMessageAttachmentsListProps {
  fileAttachmentIds: MessageFileAttachmentIDs;
  attachedFileAttachmentIds: MessageFileAttachmentIDs;
  selectedLifestyleIds: MessageSelectedLifestyleIDs;
  selectedProductIds: MessageSelectedLifestyleIDs;
  selectedCompanyProductIds: MessageSelectedLifestyleIDs;
  selectedMaterialIds: MessageSelectedMaterialIDs;
  colors: MessageColors;
  setValue: UseFormSetValue<ItemCreateMessageFormData>;
  allUploadingFiles?: { [id: string]: DropzoneUploadedFileItem };
  onRemoveUploadingFile?: (id: string) => void;
}

function ItemCreateMessageAttachmentsList({
  fileAttachmentIds,
  attachedFileAttachmentIds,
  selectedLifestyleIds,
  selectedProductIds,
  selectedCompanyProductIds,
  selectedMaterialIds,
  colors,
  setValue,
  allUploadingFiles,
  onRemoveUploadingFile
}: ItemCreateMessageAttachmentsListProps) {
  const allFileAttachmentIds = useMemo(
    () => [...fileAttachmentIds, ...attachedFileAttachmentIds],
    [fileAttachmentIds, attachedFileAttachmentIds]
  );

  const {
    fileAttachments,
    fileAttachmentsError,
    changeFileAttachmentsFilters,
    fileAttachmentsFilters
  } = useFileAttachments<FetchDropzoneFileAttachmentsQueryResponse>({
    cacheKey: FileAttachmentCache.fileAttachmentsInCreateMessageForm(),
    query: FETCH_DROPZONE_FILE_ATTACHMENTS_QUERY,
    initialFilters: {
      id: {
        in: allFileAttachmentIds
      },
      ext: { notIn: Files.imageExtensions }
    },
    initialLimit: 1000,
    options: {
      enabled: !isEmpty(allFileAttachmentIds),
      enabledPlaceholder: false
    }
  });

  const sortedFileAttachments = useMemo(
    () => sortMessageFileAttachments(fileAttachments),
    [fileAttachments]
  );

  useEffect(() => {
    if (!isEqual(fileAttachmentsFilters?.id?.in, allFileAttachmentIds)) {
      changeFileAttachmentsFilters({
        id: {
          in: allFileAttachmentIds
        }
      });
    }
  }, [
    allFileAttachmentIds,
    changeFileAttachmentsFilters,
    fileAttachmentsFilters?.id?.in
  ]);

  const {
    fileAttachments: imageAttachments,
    fileAttachmentsError: imagesError,
    changeFileAttachmentsFilters: changeImageAttachmentsFilters,
    fileAttachmentsFilters: imageAttachmentsFilters
  } = useFileAttachments<FetchDropzoneFileAttachmentsQueryResponse>({
    cacheKey: FileAttachmentCache.imageFileAttachmentsInCreateMessageForm(),
    query: FETCH_DROPZONE_FILE_ATTACHMENTS_QUERY,
    initialFilters: {
      id: {
        in: allFileAttachmentIds
      },
      ext: { in: Files.imageExtensions }
    },
    initialLimit: 1000,
    options: {
      enabled: !isEmpty(allFileAttachmentIds),
      enabledPlaceholder: false
    }
  });

  const sortedImageAttachments = useMemo(
    () => sortMessageFileAttachments(imageAttachments),
    [imageAttachments]
  );

  useEffect(() => {
    if (!isEqual(imageAttachmentsFilters?.id?.in, allFileAttachmentIds)) {
      changeImageAttachmentsFilters({
        id: {
          in: allFileAttachmentIds
        }
      });
    }
  }, [
    allFileAttachmentIds,
    changeImageAttachmentsFilters,
    imageAttachmentsFilters?.id?.in
  ]);

  const {
    selectedLifestyles,
    selectedLifestylesError,
    changeSelectedLifestylesFilters,
    selectedLifestylesFilters
  } = useSelectedLifestyles<FetchSelectedLifestylesQueryResponse>({
    cacheKey: SelectedLifestyleCache.selectedLifestyleInCreateMessageForm(),
    query: FETCH_SELECTED_LIFESTYLES_QUERY,
    initialFilters: {
      id: { in: selectedLifestyleIds }
    },
    initialLimit: 1000,
    options: {
      enabled: !isEmpty(selectedLifestyleIds)
    }
  });

  useEffect(() => {
    if (!isEqual(selectedLifestylesFilters?.id?.in, selectedLifestyleIds)) {
      changeSelectedLifestylesFilters({
        id: {
          in: selectedLifestyleIds
        }
      });
    }
  }, [
    changeSelectedLifestylesFilters,
    selectedLifestyleIds,
    selectedLifestylesFilters?.id?.in
  ]);

  const {
    selectedProducts,
    selectedProductsError,
    changeSelectedProductsFilters,
    selectedProductsFilters
  } = useSelectedProducts<FetchSelectedProductsQueryResponse>({
    cacheKey: SelectedProductCache.selectedProductInCreateMessageForm(),
    query: FETCH_SELECTED_PRODUCTS_QUERY,
    initialFilters: {
      id: { in: selectedProductIds }
    },
    initialLimit: 1000,
    options: {
      enabled: !isEmpty(selectedProductIds)
    }
  });

  useEffect(() => {
    if (!isEqual(selectedProductsFilters?.id?.in, selectedProductIds)) {
      changeSelectedProductsFilters({
        id: {
          in: selectedProductIds
        }
      });
    }
  }, [
    changeSelectedProductsFilters,
    selectedProductIds,
    selectedProductsFilters?.id?.in
  ]);

  const {
    selectedProducts: selectedCompanyProducts,
    selectedProductsError: selectedCompanyProductsError,
    changeSelectedProductsFilters: changeSelectedCompanyProductsFilters,
    selectedProductsFilters: selectedCompanyProductsFilters
  } = useSelectedProducts<FetchSelectedProductsQueryResponse>({
    cacheKey: SelectedProductCache.selectedCompanyProductInCreateMessageForm(),
    query: FETCH_SELECTED_PRODUCTS_QUERY,
    initialFilters: {
      id: { in: selectedCompanyProductIds }
    },
    initialLimit: 1000,
    options: {
      enabled: !isEmpty(selectedCompanyProductIds)
    }
  });

  useEffect(() => {
    if (
      !isEqual(
        selectedCompanyProductsFilters?.id?.in,
        selectedCompanyProductIds
      )
    ) {
      changeSelectedCompanyProductsFilters({
        id: {
          in: selectedCompanyProductIds
        }
      });
    }
  }, [
    changeSelectedCompanyProductsFilters,
    selectedCompanyProductIds,
    selectedCompanyProductsFilters?.id?.in
  ]);

  const {
    selectedMaterials,
    selectedMaterialsError,
    changeSelectedMaterialsFilters,
    selectedMaterialsFilters
  } = useSelectedMaterials<FetchSelectedMaterialsQueryResponse>({
    cacheKey: SelectedMaterialCache.selectedMaterialInCreateMessageForm(),
    query: FETCH_SELECTED_MATERIALS_QUERY,
    initialFilters: {
      id: { in: selectedMaterialIds }
    },
    initialLimit: 1000,
    options: {
      enabled: !isEmpty(selectedMaterialIds)
    }
  });

  useEffect(() => {
    if (!isEqual(selectedMaterialsFilters?.id?.in, selectedMaterialIds)) {
      changeSelectedMaterialsFilters({
        id: {
          in: selectedMaterialIds
        }
      });
    }
  }, [
    changeSelectedMaterialsFilters,
    selectedMaterialIds,
    selectedMaterialsFilters?.id?.in
  ]);

  const handleRemoveColor = useCallback<(color: HexColor) => void>(
    (color) => {
      const newColors = colors.filter((c) => c !== color);
      setValue(MessageFields.COLORS, newColors);
    },
    [colors, setValue]
  );

  const handleRemoveFile = useCallback<(id: FileAttachmentID) => void>(
    (id) => {
      if (
        some(fileAttachmentIds, (fileAttachmentId) => fileAttachmentId === id)
      ) {
        return setValue(
          MessageFields.FILE_ATTACHMENT_IDS,
          filter(
            fileAttachmentIds,
            (fileAttachmentId) => fileAttachmentId !== id
          )
        );
      }

      if (
        some(
          attachedFileAttachmentIds,
          (fileAttachmentId) => fileAttachmentId === id
        )
      ) {
        return setValue(
          MessageFields.ATTACHED_FILE_ATTACHMENT_IDS,
          filter(
            attachedFileAttachmentIds,
            (fileAttachmentId) => fileAttachmentId !== id
          )
        );
      }
    },
    [attachedFileAttachmentIds, fileAttachmentIds, setValue]
  );

  const handleRemoveSelectedProduct = useCallback<
    (id: SelectedProductID) => void
  >(
    (id) => {
      setValue(
        MessageFields.SELECTED_PRODUCT_IDS,
        filter(selectedProductIds, (selectProductId) => selectProductId !== id)
      );
    },
    [selectedProductIds, setValue]
  );

  const handleRemoveSelectedCompanyProduct = useCallback<
    (id: SelectedProductID) => void
  >(
    (id) => {
      setValue(
        MessageFields.SELECTED_COMPANY_PRODUCT_IDS,
        filter(
          selectedCompanyProductIds,
          (selectProductId) => selectProductId !== id
        )
      );
    },
    [selectedCompanyProductIds, setValue]
  );

  const handleRemoveSelectedLifestyle = useCallback<
    (id: SelectedLifestyleID) => void
  >(
    (id) => {
      setValue(
        MessageFields.SELECTED_LIFESTYLE_IDS,
        filter(
          selectedLifestyleIds,
          (selectedLifestyleId) => selectedLifestyleId !== id
        )
      );
    },
    [selectedLifestyleIds, setValue]
  );

  const handleRemoveSelectedMaterial = useCallback<
    (id: SelectedMaterialID) => void
  >(
    (id) => {
      setValue(
        MessageFields.SELECTED_MATERIAL_IDS,
        filter(
          selectedMaterialIds,
          (selectedMaterialId) => selectedMaterialId !== id
        )
      );
    },
    [selectedMaterialIds, setValue]
  );

  return (
    <div className="gap-1 grid grid-flow-col justify-start">
      <AlertMessage
        message={
          fileAttachmentsError ||
          imagesError ||
          selectedLifestylesError ||
          selectedProductsError ||
          selectedCompanyProductsError ||
          selectedMaterialsError
        }
      />
      {map(keys(allUploadingFiles), (id) => (
        <ItemCreateMessageAttachmentsListUploadingFile
          key={id}
          id={id}
          fileAttachment={allUploadingFiles[id]}
          removeFile={onRemoveUploadingFile}
        />
      ))}
      {map(sortedImageAttachments, (fileAttachment) => (
        <ItemCreateMessageAttachmentsListImage
          key={fileAttachment.id}
          attachment={fileAttachment}
          removeImage={handleRemoveFile}
          type={ItemCreateMessageAttachmentsListImageTypes.FILE_ATTACHMENT}
        />
      ))}
      {map(selectedLifestyles, (lifestyle) => (
        <ItemCreateMessageAttachmentsListImage
          key={lifestyle.id}
          attachment={lifestyle}
          removeImage={handleRemoveSelectedLifestyle}
          type={ItemCreateMessageAttachmentsListImageTypes.SELECTED_LIFESTYLE}
        />
      ))}
      {map(selectedProducts, (product) => (
        <ItemCreateMessageAttachmentsListImage
          key={product.id}
          attachment={product}
          removeImage={handleRemoveSelectedProduct}
          type={ItemCreateMessageAttachmentsListImageTypes.SELECTED_PRODUCT}
        />
      ))}
      {map(selectedCompanyProducts, (product) => (
        <ItemCreateMessageAttachmentsListImage
          key={product.id}
          attachment={product}
          removeImage={handleRemoveSelectedCompanyProduct}
          type={ItemCreateMessageAttachmentsListImageTypes.SELECTED_PRODUCT}
        />
      ))}
      {map(selectedMaterials, (material) => (
        <ItemCreateMessageAttachmentsListImage
          key={material.id}
          attachment={material}
          removeImage={handleRemoveSelectedMaterial}
          type={ItemCreateMessageAttachmentsListImageTypes.SELECTED_MATERIAL}
        />
      ))}
      {map(colors, (color) => (
        <ItemCreateMessageAttachmentsListColor
          key={color}
          color={color}
          removeColor={handleRemoveColor}
        />
      ))}
      {map(sortedFileAttachments, (fileAttachment) => (
        <ItemCreateMessageAttachmentsListFile
          key={fileAttachment.id}
          fileAttachment={fileAttachment}
          removeFile={handleRemoveFile}
        />
      ))}
    </div>
  );
}

export default ItemCreateMessageAttachmentsList;
