import { gql } from 'graphql-request';

import { TaskNanoID, TaskUUID } from '../tasksTypes';

export interface UploadTaskLdAssetQueryResponse {
  uuid: TaskUUID;
  nanoId: TaskNanoID;
}

export const UPLOAD_TASK_LD_ASSET_QUERY = gql`
  mutation UploadTaskLdAsset($uuid: ID!) {
    uploadTaskLdAsset(input: { uuid: $uuid }) {
      recordUuid
      recordNanoId
      status
      record {
        uuid
        nanoId
      }
      errors {
        fullMessages
      }
    }
  }
`;
