import React, { Fragment, useCallback, useRef, useState } from 'react';
import compact from 'lodash/compact';
import filter from 'lodash/filter';
import isEmpty from 'lodash/isEmpty';
import map from 'lodash/map';

import { SelectCompanyProductsModalButtonRequiredProps } from './SelectCompanyProductsModalButton.types';
import { ErrorMessage, I18nText } from '../../../types';
import { FetchProductsSetByUserIdScopeType } from '../../../main/productsSets/productsSetsTypes';
import { ProductsListOnProductsSelect } from '../../../main/products/components/lists/ProductsList';
import { TeamNanoID } from '../../../main/teams/teamsTypes';

import {
  FETCH_TEAMS_USERS_QUERY,
  FetchTeamsUsersQueryResponse
} from '../../../main/teamsUsers/queries/fetchTeamsUsers.query';

import { useModal } from '../../modals/hooks/useModal';
import { usePaginatedTeamsUsers } from '../../../main/teamsUsers/hooks/usePaginatedTeamsUsers';

import { SimpleModal } from '../../modals/SimpleModal';

import { ProductsIndexContent } from '../../../main/products/components/contents/ProductsIndexContent';
import { PureTooltipIconButtonHelper } from '../../buttons/PureTooltipIconButtonHelper';

import { ProductCache } from '../../../main/products/ProductCache';
import { ProductsSetCache } from '../../../main/productsSets/ProductsSetCache';
import { TeamsUserCache } from '../../../main/teamsUsers/TeamsUserCache';

interface SelectCompanyProductsModalButtonBaseProps {
  value?: string[];
  disabled?: boolean;
  submitErrorMessage?: ErrorMessage;
  productsSetScope?: FetchProductsSetByUserIdScopeType;
  companyNanoId: TeamNanoID;
}

interface SelectCompanyProductsModalButtonWithTextProps {
  buttonText: string;
  i18nText?: never;
}

interface SelectCompanyProductsModalButtonWithI18nTextProps {
  buttonText?: never;
  i18nText: I18nText;
}

interface SelectCompanyProductsModalButtonWithOnSubmitProps {
  onSubmit: (value: string[]) => Promise<unknown>;
  onChange?: never;
}

interface SelectCompanyProductsModalButtonWithOnChangeProps {
  onSubmit?: never;
  onChange: (value: string[]) => void;
}

type SelectCompanyProductsModalButtonProps =
  SelectCompanyProductsModalButtonBaseProps &
    (
      | SelectCompanyProductsModalButtonWithTextProps
      | SelectCompanyProductsModalButtonWithI18nTextProps
    ) &
    (
      | SelectCompanyProductsModalButtonWithOnChangeProps
      | SelectCompanyProductsModalButtonWithOnSubmitProps
    );

function SelectCompanyProductsModalButton({
  companyNanoId,
  value = [],
  disabled,
  onChange,
  onSubmit,
  className,
  i18nTitle,
  icon,
  iconClassName,
  modalIcon,
  i18nSubmitText,
  tooltipPlacement,
  tooltipI18nText,
  i18nText,
  buttonText,
  withoutInitialFocus,
  productsSetScope
}: SelectCompanyProductsModalButtonProps &
  SelectCompanyProductsModalButtonRequiredProps) {
  const { teamsUsers } = usePaginatedTeamsUsers<FetchTeamsUsersQueryResponse>({
    cacheKey: TeamsUserCache.teamTeamsUsersCacheKey(companyNanoId),
    query: FETCH_TEAMS_USERS_QUERY,
    initialFilters: { teamNanoId: { eq: companyNanoId } },
    initialLimit: 1000,
    options: {
      withoutPrefetch: true,
      enabled: !!companyNanoId,
      enabledPlaceholder: false
    }
  });

  const clientIds = map(teamsUsers, (teamUser) => teamUser?.user?.id);
  const noClients = isEmpty(clientIds);

  const selectedProductIds = useRef<string[]>([]);
  const attachProductIds = useRef<string[]>(value);
  const [imageLightboxOpen, setImageLightboxOpen] = useState<boolean>(false);

  const handleOpen = useCallback<() => void>(() => {
    attachProductIds.current = value;
    selectedProductIds.current = [];
  }, [value]);

  const handleClose = useCallback<() => void>(() => {
    const selectedProductsIds = filter(attachProductIds.current, (productId) =>
      selectedProductIds.current.includes(productId)
    );

    if (onChange) {
      onChange(selectedProductsIds);
    } else {
      return onSubmit?.(selectedProductsIds);
    }
  }, [onChange, onSubmit]);

  const { isOpen, showModal, hideModal } = useModal({
    onOpen: handleOpen,
    onClose: handleClose,
    preventModalClose: imageLightboxOpen
  });

  const handleProductsSelect = useCallback<ProductsListOnProductsSelect>(
    (selectedProducts) => {
      selectedProductIds.current = selectedProducts.map(
        (selectedProduct) => selectedProduct?.id
      );
    },
    []
  );

  const handleProductsAttach = useCallback<ProductsListOnProductsSelect>(
    (attachProducts) => {
      const selectedProductsIds = compact(
        attachProducts.map((selectedProduct) => selectedProduct.id)
      );

      attachProductIds.current = selectedProductsIds;

      hideModal();
    },
    [hideModal]
  );

  const togglePreventModalClose = useCallback<() => void>(
    () => setImageLightboxOpen((prevState) => !prevState),
    [setImageLightboxOpen]
  );

  return (
    <Fragment>
      {buttonText ? (
        <PureTooltipIconButtonHelper
          className={className}
          disabled={disabled || noClients}
          icon={icon}
          iconClassName={iconClassName}
          tooltipPlacement={tooltipPlacement}
          tooltipI18nText={tooltipI18nText}
          text={buttonText}
          onClick={showModal}
        />
      ) : (
        <PureTooltipIconButtonHelper
          className={className}
          disabled={disabled || noClients}
          icon={icon}
          iconClassName={iconClassName}
          tooltipPlacement={tooltipPlacement}
          tooltipI18nText={tooltipI18nText}
          i18nText={i18nText}
          onClick={showModal}
        />
      )}

      <SimpleModal
        isOpen={isOpen}
        hideModal={hideModal}
        childrenClassName="flex-1 flex overflow-hidden"
        i18nTitle={i18nTitle}
        modalIcon={modalIcon}
        modalSize="full"
        i18nSubmitText={i18nSubmitText}
        disabled={disabled || noClients}
        isLoading={disabled}
        withoutInitialFocus={withoutInitialFocus}
        withoutButtons
      >
        <ProductsIndexContent
          onProductsAttach={handleProductsAttach}
          onProductsSelect={handleProductsSelect}
          togglePreventModalClose={togglePreventModalClose}
          clientIds={clientIds}
          companyNanoId={companyNanoId}
          initialFilters={{ clientIds, blocked: false }}
          initialLimit={48}
          productsBaseCacheKey={ProductCache.companyLibraryCacheKey(
            companyNanoId
          )}
          productsCacheKey={ProductCache.companyLibraryCacheKey(companyNanoId)}
          productsSetCacheKey={ProductsSetCache.showCompanyLibraryCacheKey(
            companyNanoId
          )}
          productsSetScope={productsSetScope}
          // withoutActionError={withoutActionError}
          withoutAddButton
          withoutMyCompanyFavorite
          isModal
        />
      </SimpleModal>
    </Fragment>
  );
}

export default SelectCompanyProductsModalButton;
