import React, { useCallback, useState } from 'react';
import cl from 'classnames';
import compact from 'lodash/compact';
import values from 'lodash/values';

import { ErrorMessage, IsLoading } from '../../../../../types';

import {
  FetchMessagesCacheKey,
  FetchMessagesFilters,
  MessageFields
} from '../../../messagesTypes';

import { IconsEnum } from '../../../../../assets/icons/types';
import { ItemCreateMessageFormSubmit } from './ItemCreateMessageForm.types';
import {
  ItemMessagesListFilterMessages,
  ItemMessagesListMessages
} from '../../list/ItemMessagesList';
import { ProjectID, ProjectNanoID } from '../../../../projects/projectsTypes';
import { TaskID, TaskNanoID } from '../../../../tasks/tasksTypes';
import { TeamNanoID } from '../../../../teams/teamsTypes';

import { useTranslate } from '../../../../../common/hooks/useTranslate';
import {
  ItemCreateMessageFormChange,
  ItemCreateMessageFormData,
  useItemCreateMessageForm
} from './hooks/useItemCreateMessageForm';
import { useItemCreateMessageFormDropzoneState } from './hooks/useItemCreateMessageFormDropzoneState';

import { CreateMessageFromAiDetectProductsSearchModalButton } from '../../modalButtons/CreateMessageFromAiDetectProductsSearchModalButton';
import { CreateMessageFromAiProductsSearchModalButton } from '../../modalButtons/CreateMessageFromAiProductsSearchModalButton';
import { ItemCreateMessageArchivizerLocalTime } from './components/ItemCreateMessageArchivizerLocalTime';
import { ItemCreateMessageAttachDropdown } from './components/ItemCreateMessageAttachDropdown';
import { ItemCreateMessageAttachments } from './components/ItemCreateMessageAttachments';
import { ItemCreateMessageClientLocalTime } from './components/ItemCreateMessageClientLocalTime';
import { ItemCreateMessageCommandHeader } from './components/ItemCreateMessageCommandHeader';
import { ItemCreateMessageHiddenTask } from './components/ItemCreateMessageHiddenTask';
import { ItemCreateMessageRepliedMessage } from './components/ItemCreateMessageRepliedMessage';

import { AlertMessage } from '../../../../../helpers/AlertMessage';
import { CheckPermissions } from '../../../../../helpers/CheckPermissions';
import { DropzoneWrapperField } from '../../../../../helpers/FormFields/DropzoneWrapperField';
import { Form } from '../../../../../helpers/Form';
import { TooltipIconBooleanField } from '../../../../../helpers/FormFields/TooltipIconBooleanField';
import { LexicalEditorField } from '../../../../../helpers/FormFields/LexicalEditorField';
import { MarkdownHelpModalButton } from '../../modalButtons/MarkdownHelpModalButton';
import { MarkdownHelper } from '../../../../../helpers/MarkdownHelper';
import { OutsideClickHelper } from '../../../../../helpers/OutsideClickHelper';
import { PureIconSubmitButtonHelper } from '../../../../../helpers/buttons/PureIconSubmitButtonHelper';
import { PureTooltipIconButtonHelper } from '../../../../../helpers/buttons/PureTooltipIconButtonHelper';
import { TextareaAutosizeField } from '../../../../../helpers/FormFields/TextareaAutosizeField';

import {
  aiSearchKeys,
  messagesKeys,
  stringsKeys,
  words
} from '../../../../../locales/keys';

import { MessagesPermissions } from '../../../messagesConstants';
import { TooltipPlacement } from '../../../../../helpers/tooltips/tooltipsConstants';
import { isChatGptMessagesMode } from '../../../utils/isChatGptMessagesMode';

const MAX_MESSAGE_ROWS = 18;

interface ItemCreateMessageFormProps {
  cacheKeys?: FetchMessagesCacheKey[];
  errorMessage?: ErrorMessage;
  filterMessages?: ItemMessagesListFilterMessages;
  hiddenTask?: boolean;
  isEditingMessage?: boolean;
  isLoading?: IsLoading;
  lexicalEditorAutoFocus?: boolean;
  messageInitialValues?: Partial<ItemCreateMessageFormData>;
  messages?: ItemMessagesListMessages;
  messagesFilters?: FetchMessagesFilters;
  onAfterChatGptCommand?: () => void;
  onChange?: ItemCreateMessageFormChange;
  onResetEditMessage?: () => void;
  onSubmit: ItemCreateMessageFormSubmit;
  projectNanoId?: ProjectNanoID;
  sendToProjectId?: ProjectID;
  sendToTaskId?: TaskID;
  taskNanoId?: TaskNanoID;
  teamNanoId?: TeamNanoID;
  whiteboardEmbedded?: boolean;
  withArchivizerLocalTime?: boolean;
  withAttachLifestylesButton?: boolean;
  withAttachMaterialsButton?: boolean;
  withAttachProductsButton?: boolean;
  withAttachCompanyProductsButton?: boolean;
  withClientLocalTime?: boolean;
  withFullscreenDropzone?: boolean;
  withLexical?: boolean;
  withMarkdownHelp?: boolean;
  withPasteFiles?: boolean;
  withVisibleForClientsButton?: boolean;
  withoutCommands?: boolean;
  withLd?: boolean;
}

function ItemCreateMessageForm({
  cacheKeys,
  errorMessage,
  filterMessages,
  hiddenTask,
  isEditingMessage,
  isLoading,
  lexicalEditorAutoFocus = false,
  messageInitialValues,
  messages,
  messagesFilters,
  onAfterChatGptCommand,
  onChange,
  onResetEditMessage,
  onSubmit,
  projectNanoId,
  sendToProjectId,
  sendToTaskId,
  taskNanoId,
  teamNanoId,
  withArchivizerLocalTime,
  withAttachLifestylesButton,
  withAttachMaterialsButton,
  withAttachProductsButton,
  withAttachCompanyProductsButton,
  withClientLocalTime,
  withFullscreenDropzone,
  withLexical,
  withMarkdownHelp,
  withPasteFiles,
  withVisibleForClientsButton,
  withoutCommands,
  withLd
}: ItemCreateMessageFormProps) {
  const isChatGptMode = isChatGptMessagesMode({ messagesFilters });

  const [fileAttachmentModalOpened, setFileAttachmentsModalOpened] =
    useState<boolean>(false);

  const [previewTextFormatting, setPreviewTextFormatting] =
    useState<boolean>(false);

  const togglePreviewTextFormatting = useCallback<() => void>(
    () => setPreviewTextFormatting((prevState: boolean) => !prevState),
    []
  );

  const [lexicalWithFormatting, setLexicalWithFormatting] =
    useState<boolean>(true);

  const toggleLexicalWithFormatting = useCallback<() => void>(
    () => setLexicalWithFormatting((prevState: boolean) => !prevState),
    []
  );

  const {
    commandProcessing,
    control,
    handleEditorBlur,
    handleEditorFocus,
    handleFileUploaded,
    handleSetUploadedFile,
    handleSubmitCreateMessageForm,
    isEditorActive,
    isEditorEmpty,
    messageBody,
    messageCommand,
    messageVisibleForClient,
    setValue,
    validationErrors
  } = useItemCreateMessageForm({
    filterMessages,
    isChatGptMode,
    lexicalWithFormatting,
    messageInitialValues,
    messages,
    messagesFilters,
    onAfterChatGptCommand,
    onChange,
    onSubmit,
    sendToProjectId,
    sendToTaskId,
    taskNanoId,
    withAttachCompanyProductsButton,
    withAttachLifestylesButton,
    withAttachProductsButton,
    withLexical
  });

  const {
    allUploadingFiles,
    filesLoading,
    handleFileCreatedState,
    handleFileFailedState,
    handleFilesDroppedState,
    handleFileUploadedState,
    handleRemoveItem,
    handleUploadProgressState,
    removedFileIds
  } = useItemCreateMessageFormDropzoneState({
    onUploaded: handleSetUploadedFile
  });

  const handleSentKeyDown = (
    e: React.KeyboardEvent<HTMLTextAreaElement> | KeyboardEvent
  ) => {
    if ((e.ctrlKey || e.metaKey) && e.code === 'Enter') {
      e.preventDefault();
      handleSubmitCreateMessageForm();
    }
  };

  const { t } = useTranslate();

  const allValidationErrorsMessage =
    compact(values(validationErrors).map((i18nErr) => t(i18nErr))).join(', ') ||
    null;

  const markdownTextAreaField = previewTextFormatting ? (
    <div
      className="flex flex-1 overflow-hidden rounded-md self-stretch cursor-text max-h-96"
      onClick={togglePreviewTextFormatting}
    >
      <MarkdownHelper
        source={messageBody}
        className="markdown markdown-sm sm:markdown-md markdown-pre:whitespace-pre-wrap overflow-y-scroll w-full max-h-500 pr-2 pl-4 py-2.5 bg-gray-200 dark:bg-gray-800 text-sm leading-snug"
      />
    </div>
  ) : (
    <TextareaAutosizeField
      inputWrapperClassName="flex flex-1 focus-within:ring-4 focus-within:ring-blue-300 overflow-hidden rounded-md self-stretch"
      name={MessageFields.BODY}
      control={control}
      className="w-full max-h-500 pr-2 pl-4 py-2.5 bg-gray-100 dark:bg-gray-800 text-sm leading-snug outline-none border-none resize-none"
      pasteAsMarkdown
      onKeyDown={handleSentKeyDown}
      maxRows={MAX_MESSAGE_ROWS}
    />
  );

  return (
    <Form onSubmit={handleSubmitCreateMessageForm}>
      <OutsideClickHelper display="block" onOutsideClick={handleEditorBlur}>
        <div className="border-t dark:border-gray-700">
          <div className="max-w-screen-lg m-auto">
            <div className="py-1.5 px-2 sm:py-2.5 sm:px-4 bg-white dark:bg-gray-900">
              {/* Active command header */}
              <ItemCreateMessageCommandHeader isLoading={commandProcessing} />

              {/* Reply previews */}
              <CheckPermissions
                action={
                  MessagesPermissions.READ_MESSAGE_CREATE_FORM_REPLY_MESSAGE_PREVIEW
                }
              >
                <ItemCreateMessageRepliedMessage
                  control={control}
                  setValue={setValue}
                />
              </CheckPermissions>

              {/* Attachments previews */}
              <CheckPermissions
                action={
                  MessagesPermissions.READ_MESSAGE_CREATE_FORM_ATTACHED_PREVIEW
                }
              >
                <ItemCreateMessageAttachments
                  control={control}
                  setValue={setValue}
                  allUploadingFiles={allUploadingFiles}
                  onRemoveUploadingFile={handleRemoveItem}
                />
              </CheckPermissions>

              {/* Lexical textarea and dropzone */}
              <div className="grid grid-cols-[auto_1fr_auto] gap-x-1 gap-y-1.5 create-message-input-tour">
                <DropzoneWrapperField
                  name={MessageFields.FILE_ATTACHMENT_IDS}
                  control={control}
                  type="fileAttachments"
                  withFullscreenDropzone={
                    withFullscreenDropzone && !fileAttachmentModalOpened
                  }
                  withPasteFiles={withPasteFiles}
                  className={cl('flex w-full', {
                    'col-span-full': isEditorActive
                  })}
                  onFileCreated={handleFileCreatedState}
                  onFileFailed={handleFileFailedState}
                  onFilesAccepted={handleFilesDroppedState}
                  onFileUploaded={handleFileUploadedState}
                  onUploadProgress={handleUploadProgressState}
                  removedFileIds={removedFileIds}
                >
                  {withLexical ? (
                    <LexicalEditorField
                      autoFocus={lexicalEditorAutoFocus}
                      companyNanoId={teamNanoId}
                      control={control}
                      name={MessageFields.LEXICAL}
                      onFocus={handleEditorFocus}
                      onKeyDown={handleSentKeyDown}
                      projectNanoId={projectNanoId}
                      taskNanoId={taskNanoId}
                      withFormatting={lexicalWithFormatting}
                      withoutCommands={withoutCommands}
                    />
                  ) : (
                    markdownTextAreaField
                  )}
                </DropzoneWrapperField>

                {/* Toolbar buttons */}
                <div
                  className={cl('flex gap-0.5 flex-1 items-center', {
                    'order-first': !isEditorActive
                  })}
                >
                  <div className="relative flex items-center justify-center">
                    <ItemCreateMessageAttachDropdown
                      control={control}
                      messageVisibleForClient={messageVisibleForClient}
                      onFileUploaded={handleFileUploaded}
                      outerFilesLoading={filesLoading}
                      projectId={sendToProjectId}
                      projectNanoId={projectNanoId}
                      setFileAttachmentsModalOpened={
                        setFileAttachmentsModalOpened
                      }
                      taskId={sendToTaskId}
                      taskNanoId={taskNanoId}
                      withAttachLifestylesButton={withAttachLifestylesButton}
                      withAttachMaterialsButton={withAttachMaterialsButton}
                      withAttachProductsButton={withAttachProductsButton}
                      withAttachCompanyProductsButton={
                        withAttachCompanyProductsButton
                      }
                      withFullscreenDropzone={withFullscreenDropzone}
                      teamNanoId={teamNanoId}
                      withLd={withLd}
                    />
                  </div>

                  <CheckPermissions
                    action={
                      MessagesPermissions.READ_MESSAGE_CREATE_FORM_AI_SEARCH_MODAL_BUTTON
                    }
                  >
                    {sendToProjectId || sendToTaskId ? (
                      <CreateMessageFromAiProductsSearchModalButton
                        cacheKeys={cacheKeys}
                        className="p-1 rounded-md inline-flex items-center whitespace-nowrap text-sm font-medium leading-6 focus:ring-base text-gray-500 dark:text-gray-400 hover:text-gray-800 dark:hover:text-gray-100 focus:ring-offset-0"
                        disabled={isLoading}
                        icon={IconsEnum.SEARCH_AI_OUTLINE}
                        iconClassName="h-5 w-5"
                        projectId={sendToProjectId}
                        taskId={sendToTaskId}
                        tooltipI18nText={aiSearchKeys.aiPoweredSearch}
                      />
                    ) : null}
                  </CheckPermissions>

                  <CheckPermissions
                    action={
                      MessagesPermissions.READ_MESSAGE_CREATE_FORM_AI_DETECT_SEARCH_MODAL_BUTTON
                    }
                  >
                    {sendToProjectId || sendToTaskId ? (
                      <CreateMessageFromAiDetectProductsSearchModalButton
                        cacheKeys={cacheKeys}
                        className="p-1 rounded-md inline-flex items-center whitespace-nowrap text-sm font-medium leading-6 focus:ring-base text-gray-500 dark:text-gray-400 hover:text-gray-800 dark:hover:text-gray-100 focus:ring-offset-0"
                        disabled={isLoading}
                        icon={IconsEnum.SEARCH_AI_OUTLINE}
                        iconClassName="h-5 w-5"
                        projectId={sendToProjectId}
                        taskId={sendToTaskId}
                        tooltipI18nText={aiSearchKeys.aiPoweredSearchDetect}
                      />
                    ) : null}
                  </CheckPermissions>

                  {isEditorActive && (
                    <>
                      {!withLexical && (
                        <PureTooltipIconButtonHelper
                          className={cl(
                            'p-1 rounded-md inline-flex items-center whitespace-nowrap text-sm font-medium leading-6 focus:ring-base focus:ring-offset-0',
                            previewTextFormatting
                              ? 'text-blue-600 hover:text-blue-700 bg-blue-500 bg-opacity-20 hover:bg-opacity-30 dark:text-blue-400 dark:hover:text-blue-300'
                              : 'text-gray-500 dark:text-gray-400 hover:text-gray-800 dark:hover:text-gray-100 '
                          )}
                          icon={IconsEnum.HASH_OUTLINE}
                          iconClassName="h-5 w-5"
                          onClick={togglePreviewTextFormatting}
                          tooltipI18nText={stringsKeys.previewTextFormatting}
                          tooltipPlacement={TooltipPlacement.TOP}
                        />
                      )}

                      <CheckPermissions
                        action={
                          MessagesPermissions.READ_MESSAGE_CREATE_FORM_TOGGLE_FORMATTING_BUTTON
                        }
                      >
                        {withLexical && (
                          <PureTooltipIconButtonHelper
                            className={cl(
                              'p-1 rounded-md inline-flex items-center whitespace-nowrap text-sm font-medium leading-6 focus:ring-base focus:ring-offset-0',
                              !lexicalWithFormatting
                                ? 'text-blue-600 hover:text-blue-700 bg-blue-500 bg-opacity-20 hover:bg-opacity-30 dark:text-blue-400 dark:hover:text-blue-300'
                                : 'text-gray-500 dark:text-gray-400 hover:text-gray-800 dark:hover:text-gray-100'
                            )}
                            icon={IconsEnum.HASH_OUTLINE}
                            iconClassName="h-5 w-5"
                            onClick={toggleLexicalWithFormatting}
                            tooltipI18nText={stringsKeys.convertFromMarkdown}
                            tooltipPlacement={TooltipPlacement.TOP}
                          />
                        )}
                      </CheckPermissions>

                      {withMarkdownHelp && (
                        <MarkdownHelpModalButton
                          className="p-1 rounded-md inline-flex items-center whitespace-nowrap text-sm font-medium leading-6 focus:ring-base text-gray-500 dark:text-gray-400 hover:text-gray-800 dark:hover:text-gray-100 focus:ring-offset-0"
                          iconClassName="h-5 w-5"
                        />
                      )}

                      {withVisibleForClientsButton && (
                        <TooltipIconBooleanField
                          control={control}
                          name={MessageFields.VISIBLE_FOR_CLIENT}
                          noClassName="p-1 rounded-md inline-flex items-center whitespace-nowrap text-sm font-medium leading-6 focus:outline-none focus:ring-2 focus:ring-gray-900 dark:focus:ring-gray-100 text-gray-500 dark:text-gray-400 hover:text-gray-800 dark:hover:text-gray-100  focus:ring-offset-0"
                          noIconClassName="h-5 w-5"
                          noIcon={IconsEnum.EYE_CROSSED}
                          yesClassName="p-1 rounded-md inline-flex items-center whitespace-nowrap text-sm font-medium leading-6 focus:ring-base text-blue-600 hover:text-blue-700 bg-blue-500 bg-opacity-20 hover:bg-opacity-30 dark:text-blue-400 dark:hover:text-blue-300 focus:ring-offset-0"
                          yesIcon={IconsEnum.EYE}
                          yesIconClassName="h-5 w-5"
                          yesTooltipI18nText={messagesKeys.setHiddenForClient}
                          noTooltipI18nText={messagesKeys.setVisibleForClient}
                        />
                      )}
                    </>
                  )}
                </div>

                {isEditorActive && (
                  <>
                    {/* Time */}
                    <div className="text-2xs text-gray-500 dark:text-gray-400 hidden md:flex flex-col items-end justify-center gap-0.5 truncate px-2">
                      {hiddenTask && <ItemCreateMessageHiddenTask />}
                      {withArchivizerLocalTime && (
                        <ItemCreateMessageArchivizerLocalTime />
                      )}
                      {withClientLocalTime && teamNanoId && (
                        <ItemCreateMessageClientLocalTime
                          teamNanoId={teamNanoId}
                        />
                      )}
                    </div>

                    <div className="shrink-0 justify-end flex items-center">
                      {/* Send */}
                      <PureIconSubmitButtonHelper
                        className={cl(
                          'p-1 rounded-md inline-flex items-center whitespace-nowrap text-sm font-medium leading-5 focus:ring-base shadow-sm hover:shadow-md',
                          {
                            'text-white bg-purple-500 hover:bg-purple-600':
                              (messageCommand || isChatGptMode) &&
                              !isEditorEmpty
                          },
                          {
                            'text-white bg-blue-500 hover:bg-blue-600':
                              !messageCommand && !isEditorEmpty
                          },
                          {
                            'text-gray-500 hover:text-gray-950 dark:hover:text-white hover:bg-gray-200 dark:hover:bg-gray-700':
                              isEditorEmpty
                          }
                        )}
                        icon={
                          commandProcessing || isLoading
                            ? IconsEnum.CLOCK_OUTLINE
                            : IconsEnum.PAPER_AIRPLANE_SOLID
                        }
                        iconClassName="h-5 w-5 rotate-90"
                        disabled={
                          isEditorEmpty ||
                          isLoading ||
                          commandProcessing ||
                          filesLoading
                        }
                      />

                      {/* Cancel */}
                      {isEditingMessage && onResetEditMessage ? (
                        <PureTooltipIconButtonHelper
                          className="p-1 rounded-md inline-flex items-center whitespace-nowrap text-sm font-medium leading-6 focus:outline-none focus:ring-2 focus:ring-gray-900 dark:focus:ring-gray-100 hover:text-gray-950 dark:hover:text-white hover:bg-gray-200 dark:hover:bg-gray-700 focus:ring-offset-0"
                          icon={IconsEnum.CROSS}
                          iconClassName="h-5 w-5"
                          disabled={
                            isLoading || commandProcessing || filesLoading
                          }
                          tooltipI18nText={words.cancel}
                          onClick={onResetEditMessage}
                        />
                      ) : null}
                    </div>
                  </>
                )}
              </div>

              <AlertMessage
                className="mt-2"
                message={allValidationErrorsMessage || errorMessage}
              />
            </div>
          </div>
        </div>
      </OutsideClickHelper>
    </Form>
  );
}

export default ItemCreateMessageForm;
