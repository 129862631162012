import React from 'react';
import { Controller, FieldPath } from 'react-hook-form';

import {
  ModalDropzoneFileAttachmentsFieldControl,
  ModalDropzoneFileAttachmentsFieldControlRequiredProps
} from './components/ModalDropzoneFileAttachmentsFieldControl';

import { ModalDropzoneFileAttachmentsFieldRequiredProps } from './ModalDropzoneFileAttachmentsField.types';

interface ModalDropzoneFileAttachmentsFieldProps<T> {
  name: FieldPath<T>;
  disabled?: boolean;
}

function ModalDropzoneFileAttachmentsField<T>({
  control,
  type,
  name,
  disabled,
  className,
  i18nTitle,
  icon,
  iconClassName,
  modalIcon,
  i18nText,
  i18nSubmitText,
  tooltipPlacement,
  tooltipI18nText,
  withFullscreenDropzone,
  onFileUploaded,
  setModalOpened,
  withoutInitialFocus,
  withLd
}: ModalDropzoneFileAttachmentsFieldProps<T> &
  ModalDropzoneFileAttachmentsFieldRequiredProps<T> &
  ModalDropzoneFileAttachmentsFieldControlRequiredProps) {
  return (
    <Controller
      control={control}
      name={name}
      render={({ field: { onChange, value } }) => (
        <ModalDropzoneFileAttachmentsFieldControl
          type={type}
          value={value as string[]}
          disabled={disabled}
          className={className}
          i18nTitle={i18nTitle}
          icon={icon}
          iconClassName={iconClassName}
          modalIcon={modalIcon}
          i18nText={i18nText}
          i18nSubmitText={i18nSubmitText}
          tooltipPlacement={tooltipPlacement}
          tooltipI18nText={tooltipI18nText}
          withFullscreenDropzone={withFullscreenDropzone}
          onChange={onChange}
          onFileUploaded={onFileUploaded}
          setModalOpened={setModalOpened}
          withoutInitialFocus={withoutInitialFocus}
          withLd={withLd}
        />
      )}
    />
  );
}

export default ModalDropzoneFileAttachmentsField;
