import { useCallback, useRef, useState } from 'react';
import filter from 'lodash/filter';
import find from 'lodash/find';
import fromPairs from 'lodash/fromPairs';
import omit from 'lodash/omit';
import size from 'lodash/size';

import { DropzoneHelperRemovedFileIds } from '../../../../../../../helpers/dropzone/DropzoneHelper';
import {
  S3MultipartDropzoneAreaOnFileCreated,
  S3MultipartDropzoneAreaOnFileFailed,
  S3MultipartDropzoneAreaOnFilesAccepted,
  S3MultipartDropzoneAreaOnFileUploaded,
  S3MultipartDropzoneAreaOnUploadProgress
} from '../../../../../../../helpers/dropzone/S3MultipartDropzoneArea';
import { DropzoneAreaFile } from '../../../../../../../helpers/dropzone/DropzoneArea';

import { useToastNotification } from '../../../../../../../common/hooks/useToastNotification';

import { filesKeys } from '../../../../../../../locales/keys';

export interface ItemCreateMessageFormDropzoneFiles {
  [id: string]: DropzoneAreaFile;
}

interface ItemCreateMessageFormDropzoneStateProps {
  onUploaded?: S3MultipartDropzoneAreaOnFileUploaded;
}

function useItemCreateMessageFormDropzoneState({
  onUploaded
}: ItemCreateMessageFormDropzoneStateProps) {
  const { showToastI18nNotification: showToastOnFilesDropped } =
    useToastNotification({
      i18nMessage: filesKeys.startFilesUploading,
      appearance: 'success'
    });

  const { showToastI18nNotification: showToastOnFileFailed } =
    useToastNotification({
      i18nMessage: filesKeys.failedToUpload,
      appearance: 'error'
    });

  const { showToastI18nNotification: showToastOnFileUploaded } =
    useToastNotification({
      i18nMessage: filesKeys.fileUploaded,
      appearance: 'success'
    });

  const removedFileIdsRef = useRef<DropzoneHelperRemovedFileIds>();

  const [allFiles, setAllFiles] = useState<ItemCreateMessageFormDropzoneFiles>(
    {}
  );

  const [removedFileIds, setRemovedFileIds] =
    useState<DropzoneHelperRemovedFileIds>([]);

  removedFileIdsRef.current = removedFileIds;

  const handleFilesDroppedState =
    useCallback<S3MultipartDropzoneAreaOnFilesAccepted>(
      (files) => {
        setAllFiles((prevState) => ({
          ...prevState,
          ...fromPairs<DropzoneAreaFile>(
            files
              .sort((a, b) =>
                a.name.localeCompare(b.name, undefined, {
                  numeric: true,
                  sensitivity: 'base'
                })
              )
              .map((file) => [
                file.id,
                {
                  ...file,
                  state: 'processing' as const,
                  progresses: {}
                }
              ])
          )
        }));
        showToastOnFilesDropped();
      },
      [showToastOnFilesDropped]
    );

  const handleFileCreatedState =
    useCallback<S3MultipartDropzoneAreaOnFileCreated>((file, presignedUrls) => {
      setAllFiles((prevState) => ({
        ...prevState,
        [file.id]: {
          ...prevState[file.id],
          progresses: fromPairs(
            presignedUrls.map((presignedUrl, index) => [index, 0])
          )
        }
      }));
    }, []);

  const handleUploadProgressState =
    useCallback<S3MultipartDropzoneAreaOnUploadProgress>(
      (id, partNumber, progress) => {
        if (size(removedFileIdsRef.current) > 0) {
          const isRemovedFileId = find(
            removedFileIdsRef.current,
            (removedFileId) => removedFileId === id
          );
          if (isRemovedFileId) {
            return;
          }
        }
        setAllFiles((prevState) => ({
          ...prevState,
          [id]: {
            ...prevState[id],
            progresses: {
              ...(prevState[id]?.progresses || {}),
              [partNumber]: progress
            }
          }
        }));
      },
      []
    );

  const handleFileUploadedState =
    useCallback<S3MultipartDropzoneAreaOnFileUploaded>(
      (id, uploadedId, updatedFile) => {
        setAllFiles((prevState) =>
          omit<ItemCreateMessageFormDropzoneFiles>(prevState, id)
        );
        onUploaded?.(id, uploadedId, updatedFile);
        showToastOnFileUploaded();
      },
      [onUploaded, showToastOnFileUploaded]
    );

  const handleFileFailedState =
    useCallback<S3MultipartDropzoneAreaOnFileFailed>(
      (id) => {
        setAllFiles((prevState) => ({
          ...prevState,
          [id]: {
            ...prevState[id],
            state: 'failed' as const
          }
        }));
        showToastOnFileFailed();
      },
      [showToastOnFileFailed]
    );

  const handleRemoveItem = useCallback<(id: string) => void>(
    (id: string) => {
      setAllFiles((prevState) =>
        omit<ItemCreateMessageFormDropzoneFiles>(prevState, id)
      );
      setRemovedFileIds((prevState) => [...prevState, id]);
    },
    [setAllFiles]
  );

  return {
    allUploadingFiles: omit(allFiles, removedFileIds),
    handleFilesDroppedState,
    handleFileCreatedState,
    handleUploadProgressState,
    handleFileUploadedState,
    handleFileFailedState,
    handleRemoveItem,
    removedFileIds,
    filesLoading:
      size(
        filter(
          allFiles,
          (file) =>
            file?.state === 'initialized' || file?.state === 'processing'
        )
      ) > 0
  };
}

export default useItemCreateMessageFormDropzoneState;
