import { gql } from 'graphql-request';

import {
  FileAttachmentID,
  FileAttachmentUUID,
  FileAttachmentName,
  FileAttachmentFile,
  FileAttachmentSize,
  FileAttachmentDrawing,
  FileAttachmentReference,
  FileAttachmentTexture,
  FileAttachmentCreatedAt,
  FileAttachmentExt,
  FileAttachmentOriginalFileName,
  FileAttachmentLdFileAt
} from '../fileAttachmentsTypes';

export interface FetchDropzoneFileAttachmentsQueryResponse {
  id: FileAttachmentID;
  uuid: FileAttachmentUUID;
  name: FileAttachmentName;
  originalFilename: FileAttachmentOriginalFileName;
  file: FileAttachmentFile;
  size: FileAttachmentSize;
  ext: FileAttachmentExt;
  drawing: FileAttachmentDrawing;
  ldFileAt: FileAttachmentLdFileAt;
  reference: FileAttachmentReference;
  texture: FileAttachmentTexture;
  createdAt: FileAttachmentCreatedAt;
}

export const FETCH_DROPZONE_FILE_ATTACHMENTS_QUERY = gql`
  query FileAttachments(
    $filters: FileAttachmentsFilters
    $sort: [FileAttachmentsSortEnum!]
    $limit: Int
    $offset: Int
  ) {
    fileAttachments(
      filters: $filters
      sort: $sort
      limit: $limit
      offset: $offset
    ) {
      nodes {
        id
        uuid
        name
        originalFilename
        file
        size
        ext
        drawing
        ldFileAt
        reference
        texture
        createdAt
      }
      sql
    }
  }
`;
