import {
  FetchTaskCacheKey,
  TaskGqlError,
  TaskNanoID,
  TaskUUID,
  UpdateTaskGqlQuery,
  UpdateTaskGqlStatus
} from '../../tasksTypes';

import { useUpdateQuery } from '../../../common/hooks/base/reactQuery/useUpdateQuery';

interface UploadTaskLdAssetOptions {
  query: UpdateTaskGqlQuery;
  cacheKeys?: FetchTaskCacheKey[];
}

export interface UploadTaskLdAssetResponse<UploadTaskLdAssetRecordType> {
  uploadTaskLdAsset: {
    status: UpdateTaskGqlStatus;
    recordUuid: TaskUUID;
    record: UploadTaskLdAssetRecordType;
    errors: UploadTaskLdAssetError;
  };
}

export interface UploadTaskLdAssetInput {
  uuid: TaskUUID | TaskNanoID;
}

export interface UploadTaskLdAssetError {
  fullMessages: TaskGqlError;
}

const action = 'uploadTaskLdAsset';

const scope = 'task';

const pluralScope = 'tasks';

function useUploadTaskLdAsset<UploadTaskLdAssetRecordType>({
  query,
  cacheKeys
}: UploadTaskLdAssetOptions) {
  const {
    updateQueryData,
    updateQuery,
    updateQueryReset,
    updateQueryError,
    updateQueryLoading,
    updateQueryErrorMessage
  } = useUpdateQuery<
    UploadTaskLdAssetInput,
    UploadTaskLdAssetResponse<UploadTaskLdAssetRecordType>,
    UploadTaskLdAssetError,
    UploadTaskLdAssetRecordType
  >({ action, pluralScope, scope, query, cacheKeys });

  return {
    uploadTaskLdAssetData: updateQueryData,
    uploadTaskLdAssetError: updateQueryError,
    uploadTaskLdAssetLoading: updateQueryLoading,
    uploadTaskLdAssetErrorMessage: updateQueryErrorMessage,
    uploadTaskLdAsset: updateQuery,
    uploadTaskLdAssetReset: updateQueryReset
  };
}

export default useUploadTaskLdAsset;
