import React from 'react';
import isEmpty from 'lodash/isEmpty';
import cl from 'classnames';

import { TogglePreventModalCloseAction } from '../../../../../../../helpers/modals/modalsTypes';
import { ItemMessagesListDefaultMessageItem } from './ItemMessagesListDefaultMessage.types';
import {
  FetchMessagesCacheKey,
  MessageID,
  MessageProjectUUID,
  MessageTaskUUID,
  MessageUUID,
  OnEditMessageAction
} from '../../../../../messagesTypes';
import { CheckableMessagesProps } from '../../../../../../../common/hooks/useMessagesCheckable';

import { ItemMessagesListMessageAttachmentsAmount } from '../ItemMessagesListMessageAttachmentsAmount';
import { ItemMessagesListMessageBody } from '../ItemMessagesListMessageBody';
import { ItemMessagesListMessageIdButtons } from '../ItemMessagesListMessageIdButtons';
import { ItemMessagesListMessageImages } from '../ItemMessagesListMessageImages';
import { ItemMessagesListMessageFiles } from '../ItemMessagesListMessageFiles';
import { ItemMessagesListMessageColors } from '../ItemMessagesListMessageColors';
import { ItemMessagesListMessageIcons } from '../ItemMessagesListMessageIcons';
import { ItemMessagesListMessageMenu } from '../ItemMessagesListMessageMenu';
import { ItemMessagesListMessageForwardedMessage } from '../ItemMessagesListMessageForwardedMessage';
import { ItemMessagesListMessageSendEmailNotificationButton } from '../ItemMessagesListMessageSendEmailNotificationButton';
import { ItemMessagesListMessageTodoList } from '../ItemMessagesListMessageTodoList';
import { ItemMessagesListMessageCheckBoxField } from '../ItemMessagesListMessageCheckBoxField';
import { ItemMessagesListMessageRepliedMessage } from '../ItemMessagesListMessageRepliedMessage';

import { UserAvatarLink } from '../../../../../../common/helpers/UserAvatarLink';
import { UserLink } from '../../../../../../common/helpers/UserLink';
import { CheckPermissions } from '../../../../../../../helpers/CheckPermissions';
import { TooltipSingletonSourceWrapper } from '../../../../../../../helpers/tooltips/TooltipSingletonSourceWrapper';

import { MessagesPermissions } from '../../../../../messagesConstants';
import { TooltipPlacement } from '../../../../../../../helpers/tooltips/tooltipsConstants';

type ItemMessagesListDefaultMessageProps = {
  message: ItemMessagesListDefaultMessageItem;
  messagesCacheKey: FetchMessagesCacheKey;
  onReplyMessage?: (messageId: MessageID) => void;
  prevSameAuthor: boolean;
  nextSameAuthor: boolean;
  forwardMessageProjectUuid: MessageProjectUUID;
  forwardMessageTaskUuid?: MessageTaskUUID;
  withEditMessage?: boolean;
  withoutDropdownMenu?: boolean;
  withoutOpenInWhiteboardLink?: boolean;
  onEditMessage?: OnEditMessageAction;
  togglePreventModalClose?: TogglePreventModalCloseAction;
  jumpToMessage?: (message: { id: MessageID; uuid: MessageUUID }) => void;
  markedUnhappyMessages?: boolean;
} & CheckableMessagesProps;

function ItemMessagesListDefaultMessage({
  forwardMessageProjectUuid,
  forwardMessageTaskUuid,
  message,
  messagesCacheKey,
  onReplyMessage,
  nextSameAuthor,
  onEditMessage,
  prevSameAuthor,
  togglePreventModalClose,
  withEditMessage,
  withoutDropdownMenu,
  withoutOpenInWhiteboardLink,
  checkedMessages,
  onSetCheckedMessage,
  jumpToMessage,
  markedUnhappyMessages
}: ItemMessagesListDefaultMessageProps) {
  const withoutBody = isEmpty(message.body);
  const withoutTodoList = isEmpty(message.todoItems);

  return (
    <div
      id={`message-${message.uuid}`}
      className={cl('mr-7', nextSameAuthor ? 'mb-0.5' : 'mb-5')}
    >
      <div className="flex group">
        <ItemMessagesListMessageCheckBoxField
          message={message}
          checkedMessages={checkedMessages}
          onSetCheckedMessage={onSetCheckedMessage}
        />
        <div className="h-7 w-7 sm:h-9 sm:w-9 mr-2 flex-shrink-0">
          {prevSameAuthor ? null : (
            <UserAvatarLink
              className="max-w-none mt-5 w-full h-full rounded-full bg-gray-200 dark:bg-gray-800"
              user={message.user}
            />
          )}
        </div>
        <div className="flex flex-col flex-1">
          {prevSameAuthor ? null : (
            <div className="text-xs mb-0.5 ml-2 text-gray-600 dark:text-gray-400">
              <CheckPermissions action={MessagesPermissions.READ_MESSAGE_ID}>
                <span>{message.id}</span>
              </CheckPermissions>
              <CheckPermissions
                action={MessagesPermissions.READ_MESSAGE_THREAD_KEY_ID}
              >
                &nbsp;
                <span>{message.threadKeyId}</span>
              </CheckPermissions>
              <ItemMessagesListMessageIdButtons message={message} />{' '}
              <UserLink user={message.user} text={message.user?.fullName} />
            </div>
          )}
          <div className="w-full">
            <CheckPermissions
              action={MessagesPermissions.READ_MESSAGE_FORWARD_MESSAGE}
            >
              {isEmpty(message.forwardedMessage) ? null : (
                <ItemMessagesListMessageForwardedMessage
                  message={message}
                  messagesCacheKey={messagesCacheKey}
                  onReplyMessage={onReplyMessage}
                  forwardMessageProjectUuid={forwardMessageProjectUuid}
                  forwardMessageTaskUuid={forwardMessageTaskUuid}
                  checkedMessages={checkedMessages}
                  onSetCheckedMessage={onSetCheckedMessage}
                  markedUnhappyMessages={markedUnhappyMessages}
                />
              )}
            </CheckPermissions>

            <CheckPermissions
              action={MessagesPermissions.READ_MESSAGE_REPLY_MESSAGE}
            >
              {isEmpty(message.repliedMessage) ? null : (
                <ItemMessagesListMessageRepliedMessage
                  message={message}
                  jumpToMessage={jumpToMessage}
                  markedUnhappyMessages={markedUnhappyMessages}
                />
              )}
            </CheckPermissions>
            <div className="group flex justify-start">
              {withoutBody ? null : (
                <div>
                  <ItemMessagesListMessageBody
                    message={message}
                    messagesCacheKey={messagesCacheKey}
                    prevSameAuthor={prevSameAuthor}
                    nextSameAuthor={nextSameAuthor}
                    togglePreventModalClose={togglePreventModalClose}
                    withoutOpenInWhiteboardLink={withoutOpenInWhiteboardLink}
                    markedUnhappyMessages={markedUnhappyMessages}
                  />
                </div>
              )}

              {withoutTodoList ? null : (
                <div>
                  <ItemMessagesListMessageTodoList
                    message={message}
                    prevSameAuthor={prevSameAuthor}
                    nextSameAuthor={nextSameAuthor}
                  />
                </div>
              )}

              {withoutBody && withoutTodoList ? null : (
                <ItemMessagesListMessageMenu
                  forwardMessageProjectUuid={forwardMessageProjectUuid}
                  forwardMessageTaskUuid={forwardMessageTaskUuid}
                  message={message}
                  messagesCacheKey={messagesCacheKey}
                  onReplyMessage={onReplyMessage}
                  withEditMessage={withEditMessage}
                  withoutDropdownMenu={withoutDropdownMenu}
                  onEditMessage={onEditMessage}
                  checkedMessages={checkedMessages}
                  onSetCheckedMessage={onSetCheckedMessage}
                />
              )}
            </div>

            <TooltipSingletonSourceWrapper
              placement={TooltipPlacement.TOP}
              withArrow
            >
              <ItemMessagesListMessageImages
                message={message}
                messagesCacheKey={messagesCacheKey}
                onReplyMessage={onReplyMessage}
                forwardMessageProjectUuid={forwardMessageProjectUuid}
                forwardMessageTaskUuid={forwardMessageTaskUuid}
                togglePreventModalClose={togglePreventModalClose}
                withEditMessage={withEditMessage}
                withoutDropdownMenu={withoutDropdownMenu}
                onEditMessage={onEditMessage}
                checkedMessages={checkedMessages}
                onSetCheckedMessage={onSetCheckedMessage}
              />
              <ItemMessagesListMessageFiles
                message={message}
                messagesCacheKey={messagesCacheKey}
                onReplyMessage={onReplyMessage}
                forwardMessageProjectUuid={forwardMessageProjectUuid}
                forwardMessageTaskUuid={forwardMessageTaskUuid}
                withEditMessage={withEditMessage}
                withoutDropdownMenu={withoutDropdownMenu}
                onEditMessage={onEditMessage}
                checkedMessages={checkedMessages}
                onSetCheckedMessage={onSetCheckedMessage}
              />
              <ItemMessagesListMessageAttachmentsAmount message={message} />
            </TooltipSingletonSourceWrapper>

            <ItemMessagesListMessageColors
              message={message}
              messagesCacheKey={messagesCacheKey}
              onReplyMessage={onReplyMessage}
              forwardMessageProjectUuid={forwardMessageProjectUuid}
              forwardMessageTaskUuid={forwardMessageTaskUuid}
              withEditMessage={withEditMessage}
              withoutDropdownMenu={withoutDropdownMenu}
              onEditMessage={onEditMessage}
              checkedMessages={checkedMessages}
              onSetCheckedMessage={onSetCheckedMessage}
            />
            <ItemMessagesListMessageIcons
              message={message}
              messagesCacheKey={messagesCacheKey}
            />
            {withoutBody ? (
              <ItemMessagesListMessageSendEmailNotificationButton
                message={message}
                messagesCacheKey={messagesCacheKey}
                className="relative mt-1 flex items-center"
              />
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
}

export default ItemMessagesListDefaultMessage;
