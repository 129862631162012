import React from 'react';
import { Controller, FieldPath } from 'react-hook-form';

import {
  ModalSelectCompanyProductsFieldControl,
  ModalSelectCompanyProductsFieldControlRequiredProps
} from './components/ModalSelectCompanyProductsFieldControl';

import { ModalSelectCompanyProductsFieldRequiredProps } from './ModalSelectCompanyProductsField.types';
import { FetchProductsSetByUserIdScopeType } from '../../../main/productsSets/productsSetsTypes';
import { TeamNanoID } from '../../../main/teams/teamsTypes';

interface ModalSelectCompanyProductsFieldProps<T> {
  name: FieldPath<T>;
  disabled?: boolean;
  productsSetScope?: FetchProductsSetByUserIdScopeType;
  companyNanoId: TeamNanoID;
}

function ModalSelectCompanyProductsField<T>({
  control,
  name,
  disabled,
  className,
  i18nTitle,
  icon,
  iconClassName,
  i18nText,
  modalIcon,
  i18nSubmitText,
  tooltipPlacement,
  tooltipI18nText,
  withoutInitialFocus,
  productsSetScope,
  companyNanoId
}: ModalSelectCompanyProductsFieldProps<T> &
  ModalSelectCompanyProductsFieldRequiredProps<T> &
  ModalSelectCompanyProductsFieldControlRequiredProps) {
  return (
    <Controller
      control={control}
      name={name}
      render={({ field: { onChange, value } }) => (
        <ModalSelectCompanyProductsFieldControl
          value={value as string[]}
          disabled={disabled}
          className={className}
          i18nTitle={i18nTitle}
          i18nText={i18nText}
          icon={icon}
          iconClassName={iconClassName}
          modalIcon={modalIcon}
          i18nSubmitText={i18nSubmitText}
          tooltipPlacement={tooltipPlacement}
          tooltipI18nText={tooltipI18nText}
          onChange={onChange}
          withoutInitialFocus={withoutInitialFocus}
          productsSetScope={productsSetScope}
          companyNanoId={companyNanoId}
        />
      )}
    />
  );
}

export default ModalSelectCompanyProductsField;
